.page{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f8f9fa;
    .main{
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        .main-top{
            display: flex;
            justify-content: center;
            width: 100%;
            height: 300px;
            background-color: #f1f3f5;
            .title{
                margin-top: 50px;
            }
            .logo{
                object-fit: contain;
                width: 400px;
                height: fit-content;
                margin-top: 80px;
            }
        }
        .main-bottom{
            flex: 1;
            .create-img-box{
                width: 100%;
                height: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
                .btn{
                    width: 200px;
                    height: 60px;
                    border-radius: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    font-size: 20px;
                    font-weight: bold;
                    cursor: pointer;
                    background-color: #000000;
                    margin: 0 20px;
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
                    &:hover{
                        background-color: #222222;
                    }
                    // &.text-to-img-btn{
                    //     background-color: #ff1744;
                    //     &:hover{
                    //         background-color: #d50000;
                    //     }
                    // }
                    // &.img-to-img-btn{
                    //     background-color: #2979ff;
                    //     &:hover{
                    //         background-color: #0056b3;
                    //     }
                    // }
                }
            }
        }
    }
    footer{
        display: flex;
        font-size: 14px;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 60px;
        color: #aaa;
    }
}